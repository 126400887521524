import React, { useEffect } from 'react';
import Navbar from '../springworks/Navbar';
import './styles.scss';

const NotFound = () => {
    useEffect(() => {
        document.title = 'Not Found | Springworks';
    }, []);
    return (
        <div className='notfound__container'>
            <Navbar />
            <div className='notfound__container__content'>
                <div className='notfound__container__content--image'>
                    <img
                        src={'https://sw-assets.gumlet.io/common/notfound.svg'}
                        data-src={'https://sw-assets.gumlet.io/common/notfound.svg'}
                        alt={'not found'}
                        placeholder={'tracedSVG'}
                    />
                </div>
                <div className='notfound__container__content--text'>page not found</div>
            </div>
        </div>
    );
};

export default NotFound;
