import React from 'react';
import Sw404 from '../components/SW404';

const NotFound = (props) => {
    const validPaths = ['engagewith', 'remotetips', 'trivia'];
    if (validPaths.includes(props.location.pathname.replace(/\//g, '').toLowerCase())) {
        window.location.href = window.location.href.replace(
            props.location.pathname,
            props.location.pathname.toLowerCase()
        );
    } else return <Sw404 />;
};

export default NotFound;
